import Vue from 'vue'
import VueRouter from 'vue-router'
import Host from '../views/Host.vue'
import Audience from '../views/Audience.vue'
import About from '../views/About.vue'


Vue.use(VueRouter)


const routes = [
  {
    path: '/host',
    name: 'Host',
    component: Host
  },
  {
    path: '/audience',
    name: 'Audience',
    component: Audience
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/',
    name: 'Host',
    component: Host
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
