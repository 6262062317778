<template>
  <v-container fluid>
    <v-subheader>Host（配信する）</v-subheader>

    <!-- ロゴ表示 -->
    <slide-up-down :active="logo_active">
      <v-row justify="center">
        <v-col cols="10" xs="10" sm="8" md="6">
          <v-img src="../assets/logo_moon.png"></v-img>
        </v-col>
      </v-row>
    </slide-up-down>

    <!-- 配信共有画面 -->
    <ShareView v-if="hostData.hostId" :shareUrl="shareUrl" :hostId="hostData.hostId" />

    <!-- youtube URLの入力フォーム -->
    <v-row justify="center" align="center">
      <v-col v-if="!hostData.hostId" cols="11" sm="8" md="6" lg="6">
        <v-text-field outlined v-model="youtubeURL" :label="label" hide-details="true"></v-text-field>
      </v-col>

      <v-col  v-if="!hostData.hostId" cols="3" sm="2" md="1" lg="1">
        <div>
          <v-btn large @click="startSharing">配信開始</v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- 再生ビュー -->
    <v-row v-if="hostData.hostId">
      <v-col cols=8>
        <v-sheet color="#696969" class="pa-3">
          <v-text-field class="mb-2" outlined solo dense v-model="youtubeURL" :label="label" hide-details="true"></v-text-field>
          <youtube :video-id="hostData.vidId" ref="youtube" @playing="playing" @paused="paused" width="100%" height="600" :resize="true"></youtube>
        </v-sheet>
      </v-col>
      
      <v-col cols=4>
        <ChatWindow :hostId="hostData.hostId"  />
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'
import ShareView from '@/components/ShareView'
import ChatWindow from '@/components/ChatWindow'

export default {
  name: 'Host',
  data() {
    return {
      logo_active: true,
      hostData:{
        hostId: "",
        vidId: '',
        state: 2, //1は再生 2は停止 
        vidPosition: 0,
        newAudienceSynced: true,
      },
      shareUrl: "",
      youtubeURL: "",
      label: "Input youtube URL (ex. https://www.youtube.com/watch?v=~)",
      windwoWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  methods: {
    //EVENTS
    async playing() { 
      await this.getVidPosition()
      this.hostData.state = 1
      this.sendHostData()
    },
    async paused(){
      await this.getVidPosition()
      this.hostData.state = 2
      this.sendHostData()
    },
    async getVidPosition(){
      this.hostData.vidPosition = await this.player.getCurrentTime()
    },
    sendHostData(){
      firebase.database().ref(this.hostData.hostId).set({
        hostId: this.hostData.hostId,
        state: this.hostData.state,
        vidId: this.hostData.vidId,
        vidPosition: this.hostData.vidPosition,
        newAudienceSynced: this.hostData.newAudienceSynced,
      }, () => {
      })

      this.getHostData()
    },
    async syncForNewAudience(){
      await this.getVidPosition()
      this.sendHostData()
    },
    getHostData(){ //イベントリスナー込
			const self = this
			firebase.database().ref(self.hostData.hostId).on('value', function(snapshot){
        self.hostData.newAudienceSynced = snapshot.val().newAudienceSynced
      });
		},
    startSharing(){
      this.label = "※URLを変更すると動画も更新されます"
      const vidId = this.getVidId(this.youtubeURL)
      if(!vidId){
        alert("youtubeURLを入力してください")
        return
      }
      this.logo_active = false
      this.hostData.vidId = vidId
      this.hostData.hostId = this.idGenerator()
      // this.shareUrl = "https://moonvideo.net/audience/?hostId=" + this.hostData.hostId
      this.shareUrl = "http://localhost:8080/audience/?hostId=" + this.hostData.hostId
      this.sendHostData()
      // init comment
      firebase.database().ref(this.hostData.hostId).set({
        hostId: this.hostData.hostId,
        state: this.hostData.state,
        vidId: this.hostData.vidId,
        vidPosition: this.hostData.vidPosition,
        newAudienceSynced: this.hostData.newAudienceSynced,
        comments: ['動画の配信を開始しました']
      }, () => {
      })
    },
    idGenerator(){
      //使用文字の定義
        var str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        //桁数の定義
        var len = 20;
        //ランダムな文字列の生成
        var result = "";
        for(var i=0;i<len;i++){
          result += str.charAt(Math.floor(Math.random() * str.length));
        }
        return result
    },
    getVidId(url) {
      if (!url) return false;

      // 短縮urlかそうでないかを判別する
      const splitted_url = url.split('be')
      const url_descriminator = splitted_url[0].slice(-1)

      if (url_descriminator == '.') {
        // 短縮urlのとき
        return splitted_url[1].split('/')[1]
      } else if (url_descriminator == 'u') {
        // 普通のurlのとき
        const pramString = url.split('?')[1]
        let params = {};
        let rawParams = pramString.split('&');
        rawParams.forEach(function(prm){
          var kv = prm.split('=');
          params[kv[0]] = kv[1];
        });
        return params['v']
      } else {
        return false
      }
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    },
  },
  watch:{
    youtubeURL: function(newUrl){
      if(!this.getVidId(this.youtubeURL)) return false;
      this.hostData.vidId = this.getVidId(newUrl)
      this.sendHostData()
    },
    'hostData.newAudienceSynced': function(){
      this.hostData.newAudienceSynced = true
      this.syncForNewAudience()
      this.sendHostData()
		},
  },
  components: {
    ShareView,
    ChatWindow
  }
}
</script>

<style scoped>
.host {
  margin: 0px 0px 0px 0px;
}
</style>