import Vue from 'vue'
import App from './App.vue'
import router from './router'

import firebase from 'firebase'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyD0mHKs93pGuJyEXXro5z1E1jCx7cjqE1I",
  authDomain: "moon-57fd7.firebaseapp.com",
  databaseURL: "https://moon-57fd7.firebaseio.com",
  projectId: "moon-57fd7",
  storageBucket: "moon-57fd7.appspot.com",
  messagingSenderId: "797920372675",
  appId: "1:797920372675:web:f0a3d6020a95e8c22f3f76",
  measurementId: "G-2N1S57QVTD"
}
firebase.initializeApp(config)


// additional packages
import VueYoutube from 'vue-youtube'
import vuetify from './plugins/vuetify'
import SlideUpDown from 'vue-slide-up-down'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);
Vue.use(VueYoutube)

Vue.component('slide-up-down', SlideUpDown)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
