<template>
  <!-- <v-list three-line>
    <template v-for="(comment, index) in comments">
      <v-list-item
          :key="index"
          avatar
      >
        <v-list-item-avatar>
          <img :src="comment.avatar">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle class="text--primary subheading">{{comment.content}}</v-list-item-subtitle>
          <v-list-item-subtitle>
            {{comment.createdAt.toDate().toLocaleString()}}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="comment.id"></v-divider>
    </template>
  </v-list> -->
  <h1>HostId = {{hostId}}</h1>
</template>

<script>
import firebase from 'firebase'

export default {
  props: {
    'hostId': { type: String, required: true },
  },
  data() {
    return {
      hostData: {
				hostId: "",
				vidId: "",
				state: 2,
        vidPosition: 0,
        newAudienceSynced: false,
        comments: []
      },
      
    }
  },
  created() {
    // const self = this
    firebase.database().ref(this.hostId).on('value', function(snapshot){
      console.log(snapshot.val())
    });
  },
  methods: {

  }
}
</script>