<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card
        color="white"
      >
        <v-card-text>
          <span class="text-h4">
            <v-icon
              large
              color="red darken-2"
            >mdi-radiobox-marked</v-icon>
            <span>配信中</span>
          </span>

          <!-- <p>あなたの配信URLは <a :href="shareUrl">{{shareUrl}}</a></p> -->
          <span class="pl-2 pr-2">あなたのHostIDは<a :href="shareUrl">{{hostId}}</a></span>
          <ShareNetwork
            network="facebook"
            :url="shareUrl"
            title="moonvideo -YouTube動画共有サービス-"
            description="同じ部屋でyoutube動画を観るように動画を再生できるアプリ"
            quote="同じ部屋でyoutube動画を観るように動画を再生できるアプリ"
            hashtags="moonvideo"
          >
            <i class="fab fa-facebook-square pr-1"></i>
          </ShareNetwork>

          <ShareNetwork
            network="twitter"
            :url="shareUrl"
            title="moonvideo -YouTube動画共有サービス-"
            description="同じ部屋でyoutube動画を観るように動画を再生できるアプリ"
            quote="同じ部屋でyoutube動画を観るように動画を再生できるアプリ"
            hashtags="moonvideo"
          >
            <i class="fab fa-twitter-square pr-1"></i>
          </ShareNetwork>

          <ShareNetwork
            network="line"
            :url="shareUrl"
            title="moonvideo -YouTube動画共有サービス-"
            description="同じ部屋でyoutube動画を観るように動画を再生できるアプリ"
            quote="同じ部屋でyoutube動画を観るように動画を再生できるアプリ"
            hashtags="moonvideo"
          >
            <i class="fab fa-line pr-1"></i>
          </ShareNetwork>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
export default {
  props: {
    'shareUrl': { type: String, required: true },
    'hostId': { type: String, required: true },
  },
}
</script>


<style scoped>

.fab {
  font-size: 36px;
}
.fa-facebook-square{
  color: #1877f2;
}
.fa-twitter-square{
  color:#1DA1F2;
}
.fa-line{
  color:#00b900;
}
</style>