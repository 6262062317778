<template>
  <v-container fluid>
		<v-subheader>Audience（視聴する）</v-subheader>

		<slide-up-down :active="logo_active">
			<v-row justify="center">
				<v-col cols="10" xs="10" sm="8" md="6">
					<v-img src="../assets/logo_moon.png"></v-img>
				</v-col>
			</v-row>
		</slide-up-down>

		<v-row justify="center" align="center">
			<v-col cols="11" sm="8" md="6" lg="6">
				<v-text-field outlined v-model="hostData.hostId" label="input hostId here!" hide-details="true"></v-text-field>
			</v-col>
			<v-col cols="3" sm="2" md="1" lg="1">
				<v-btn large @click="getHostData">視聴開始</v-btn>
			</v-col>
		</v-row>
		<div v-if="hostData.vidId">
			<youtube :video-id="hostData.vidId" ref="youtube"></youtube>
		</div>
	</v-container>
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'

export default {
  name: 'Audience',
  data() {
    return {
			logo_active: true,
			hostData: {
				hostId: "",
				vidId: "",
				state: 2,
        vidPosition: 0,
        newAudienceSynced: false
      },
      notPlayed: -1,
      ended: 0,
      playing: 1,
      paused: 2,
      buffering: 3,
      videoCued: 5,
    };
	},
	async mounted(){
		this.hostData.hostId = this.$route.query.hostId
  },
  
  methods:{
    playVideo() {
			this.player.playVideo()
		},
		pauseVideo(){
			this.player.pauseVideo()
		},
		async getVidPosition(){
      // console.log("getVidPosition")
      return await this.player.getCurrentTime()
    },
		async getHostData(){ //イベントリスナー込
      const self = this
			this.logo_active = false
			firebase.database().ref(self.hostData.hostId).on('value', function(snapshot){
				self.hostData = snapshot.val()
      });
      // sleep いれたい
      setTimeout(() => {
        firebase.database().ref(this.hostData.hostId).update({
          newAudienceSynced: false,
        }, () => {})
      }, 5000);
    },
		resetPlayer(){
			const self = this
			this.player.cueVideoById({
				videoId: self.hostData.vidId,
				startSeconds: self.hostData.vidPosition
			})
		},
		seekVideo(seekTime){
			this.player.seekTo(seekTime, true)
		}
	},
	watch:{
		'hostData.vidId': function(){
			this.resetPlayer()
		},
		'hostData.state': function(newState){
			const self = this
			if(newState == this.playing){
				this.seekVideo(self.hostData.vidPosition, true)
				this.playVideo()
			}else if(newState == this.paused){
				this.seekVideo(self.hostData.vidPosition, true)
				this.pauseVideo()
			}
		},
		'hostData.vidPosition': function(newPos, lastPos){
      console.log({newPos, lastPos})
      this.seekVideo(newPos, true)
		},
	},
  computed: {
    player() {
      return this.$refs.youtube.player
    },
	},
}
</script>


<style scoped>
iframe {
  width: 80%;
  max-width: 650px;
}

.audience {
  margin: 0px 0px 0px 0px;
}
</style>