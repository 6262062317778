<template>
  <div>
    <v-app>
      <v-navigation-drawer app v-model="drawer" clipped>
        <v-container>
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                機能一覧
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider> -->

          <v-list dense nav>
            <v-list-item v-for="nav_list in nav_lists" :key="nav_list.name" :to="nav_list.link">
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-navigation-drawer>
      <v-app-bar dark app clipped-left>
        <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Moon</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
      
      <v-footer dark>
        Moon
      </v-footer>
    </v-app>
  </div>

</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      drawer: false,
      nav_lists: [ 
        {name: '配信する',icon: 'mdi-cast', link: '/host'},
        {name: '視聴する',icon: 'mdi-play-box-multiple-outline', link: '/audience'},
        {name: 'about',icon: 'mdi-help', link: '/about'},
      ]
    }
  },
  components: {
  },
};
</script>


<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>