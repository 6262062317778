<template>
  <v-container fluid>
    <v-parallax src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">
      <v-row
        align="center"
        justify="center"
      >
        <v-col class="text-center" cols="12">
          <h1 class="display-3 font-weight-thin mb-4">MOON</h1>
          <h4 class="headline font-weight-thin">Watch video together from anywhere.</h4>
        </v-col>
      </v-row>
    </v-parallax>
    
  
    <v-card outlined>
      <v-card-title primary-title class="layout justify-center">
        <h1 class="headline mt-4 mb-4">離れた人と動画を一緒に見よう</h1>
      </v-card-title>
      <v-row justify="center">
        <v-col cols="10" sm="10" md="8" lg="6">
          <v-img width src="../assets/couple.jpeg"></v-img> 
        </v-col>
      </v-row>
      <v-card-text class="mt-2 mb-8">
        MOONはYouTube動画の再生を共有できるサービスです。<br/>
        利用方法はYouTubeのURLを入力するだけ。<br/>
        リンクが作成されたら、友達・恋人に送ってみましょう。<br/>
        再生・一時停止・早送りなどが同期され、<br/>
        まるで同じ部屋で見ているかのようなUXを実現します。
      </v-card-text>
    </v-card>


    <v-card outlined>
      <v-card-title primary-title class="layout justify-center">
        <h1 class="headline mt-4 mb-4">お仕事にも</h1>
      </v-card-title>
      <v-row justify="center">
        <v-col cols="10" sm="10" md="8" lg="6">
          <v-img width src="../assets/bsman.jpg"></v-img> 
        </v-col>
      </v-row>
      <v-card-text class="mt-2 mb-8">
        お客様とのリモート会議などでも使用可能です。<br/>
        インストール不要なので、手間がかかりません。<br/>
        もちろんスマートフォンからの操作も可能。<br/>
        YouTube限定公開動画も配信可能です。
      </v-card-text>
    </v-card>

    <v-card outlined>
      <v-card-title primary-title class="layout justify-center">
        <h1 class="headline mt-4 mb-4">開発者</h1>
      </v-card-title>
      <v-card-text class="mt-2 mb-8">
        <v-row justify="center">
          <v-col cols="3">
            変態物理
            <a href="https://twitter.com/Mr_1484?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @Mr_1484</a>
          </v-col>
          <v-col cols="3">
            kmhumd
            <a href="https://twitter.com/kmdms5?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @kmdms5</a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>

export default {
  components: {
   
  },
}
</script>

<style scoped>
</style>


